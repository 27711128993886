<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="container">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-5">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="https://kmcorporate.com/wp-content/uploads/2021/06/KN21-tre-quarti-rev1-210610-1536x1044.jpg"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-7">
                <div class="mt-4">
                 
                  <p style="font-size: 16px; color: black; font-weight: 400">
                    Bench unit 2 ton.
                  </p>
                  <p style="font-size: 16px; color: black; font-weight: 400">
                   Model KN21 is a bench crimping unit with crimping force of 2 tons. The safety guards can be completely opened to allow an easy access to the working area and a simple and fast miniapplicator changeover. The reel holder bracket allows a simple and quick shift of the terminal feeding sense. The standard feature includes the footswitch for the cycle drive, a transparent shock-proof safety guards for a full view of the working area and the lamp for the working area lighting.
                  </p>
                </div>
                <br />
                <div class="mt-4">
                 
                  <p style="font-size: 16px; color: black; font-weight: 400">
                    On request the machine can be equipped with a crimp force monitor system, quick coupling base for clamping of the miniapplicator and a kit for use of pneumatic miniapplicators.
                  </p>
                </div>
                <br />
             
                                        
    
                <p style="font-size: 14px; color: black; font-weight: 400">
                  Data and measurements are indicative and subject to change
                  without notice. Some particular types of cables may not be
                  machined even though they fall within the range of sections
                  indicated. KM Corporate will still be happy to carry out tests
                  and supply wire-worked samples
                </p>
              </div>
            </div>
            <!-- end row -->
            <br />
            <br />
            <br />
            <div class="row">
            <div class="col-5"></div>
            <div class="col-7">
            <button @click="file()" class="btn btn-primary" style="width:100%"><i class="fa fa-download"></i> Download The Technical Sheet</button>
            </div>
            </div>
            <br><br><br>
            <div class="row">
              <h1
                style="color: black; font-weight: 800; margin-left: 40px"
                class="font-size-20 mb-3"
              >
              Gallery
            
              </h1>
              
            </div>
             <br />

            <div class="row" style="margin-left:50px;">
             
              <vue-picture-swipe :items="items"></vue-picture-swipe>
            </div>
            <!-- end row -->
          
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
   
    Header,
    Footer,

    
  },
  data(){
           return {
        items: [{
          src: 'https://kmcorporate.com/wp-content/uploads/2021/07/KN21-frontale-con-riflesso-rev1-210610-DEF-150x150.jpg',
          thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/KN21-frontale-con-riflesso-rev1-210610-DEF-150x150.jpg',
          w: 1200,
          h: 900,
          alt: 'some numbers on a grey background' // optional alt attribute for thumbnail image
        },
        {
          src: 'https://kmcorporate.com/wp-content/uploads/2021/07/KN21M-rev.1-210714-150x150.jpg',
          thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/KN21M-rev.1-210714-150x150.jpg',
          w: 1200,
          h: 900
        },
        {
          src: 'https://kmcorporate.com/wp-content/uploads/2021/07/KN21-KM-025-150x150.jpg',
          thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/KN21-KM-025-150x150.jpg',
          w: 1200,
          h: 900
        },
        {
          src: 'https://kmcorporate.com/wp-content/uploads/2021/07/KN21-KM-034-150x150.jpg',
          thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/KN21-KM-034-150x150.jpg',
          w: 1200,
          h: 900
        },
        {
          src: 'https://kmcorporate.com/wp-content/uploads/2021/07/KN21-KM-037-150x150.jpg',
          thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/KN21-KM-037-150x150.jpg',
          w: 1200,
          h: 900
        },
        {
          src: 'https://kmcorporate.com/wp-content/uploads/2021/07/KN21-KM-039-150x150.jpg',
          thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/KN21-KM-039-150x150.jpg',
          w: 1200,
          h: 900
        }
      ]};

  },

methods: {
  file(){
    window.location.href = "https://kmcorporate.com/wp-content/uploads/2021/06/Scheda_Kn21_Rev-19-01-Low.pdf";
  }
}
};
</script>